import React from "react";
import { v4 as uuidv4 } from "uuid";
import Select, { components, MultiValueGenericProps } from "react-select";
import clsx from "clsx";
import ClearUpTitle from "../../helpers/ClearUpTitle";

// import { renamedFacetsTitles } from "../../data/renamedFacetTitles";
import getRenamedFacetsTitles from "../../data/renamedFacetTitles";

interface FacetProps {
  key: string;
  facetOptions: {
    title: string;
    name: string;
    isMulti: boolean;
    isSearchable: boolean;
    options: any;
    defaultValue: string;
    onChange: any;
  };
  projectName: string;
}

function MultiValueLabel(props: MultiValueGenericProps) {
  //

  // Filter out the latest value of the selected option
  const getCurrentValue = props.selectProps.options.filter(
    (item: any) => item.value === props.data.value
  );

  // We use the latest value as option
  const newProps = getCurrentValue.length
    ? {
        ...props,
        // @ts-ignore
        children: getCurrentValue[0].label.replace(/\(\d+\)/, "").trim(),
      }
    : props;

  return (
    <div>
      <components.MultiValueLabel {...newProps} />
    </div>
  );
}

function Facet(props: FacetProps) {
  const { facetOptions, projectName } = props;

  const renamedFacetsTitles = getRenamedFacetsTitles(projectName);

  const facetTitle = renamedFacetsTitles
    .filter((item) => item.title === props.facetOptions.title)
    .map((item) => item.newTitle)[0] || (
    <ClearUpTitle title={props.facetOptions.title} />
  );

  const options = facetOptions.options?.map((option: any) => {
    return { ...option, label: option.label.replace("$#$", " > ") };
  });

  // .sort(function (a, b) {
  //   if (a.value < b.value) {
  //     return -1;
  //   }
  //   if (a.value > b.value) {
  //     return 1;
  //   }
  //   return 0;
  // });

  // Hide empty facets
  const show = options.length > 0 ? "" : "hidden";
  const classes = "pb-3 mb-3 border-b border-b-gray-2";

  return (
    <div className={clsx(classes, show)} key={uuidv4()}>
      <div className="font-bold pb-3">{facetTitle}</div>
      <Select
        {...facetOptions}
        options={options}
        components={{ MultiValueLabel }}
      />
      {/* <Select {...props.facetOptions} /> */}
    </div>
  );
}
export default Facet;
